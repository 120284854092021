<template>
        <q-card style="width: 600px; max-width: 60vw">
            <q-bar class="bg-primary text-white">
                <q-icon name="fas fa-keyboard" />
                <div>
                    
                    <small class="q-ml-sm text-size-sm">
                        <!-- {{ PdmBodycheckTaocantypeEntity.PdmBodycheckTaocantypeName }}({{
                            PdmBodycheckTaocantypeEntity.PdmBodycheckTaocantypeID
                        }}) -->
                    </small>
                </div>

                <q-space />

                <q-btn dense flat icon="close" v-close-popup>
                    <q-tooltip>关闭</q-tooltip>
                </q-btn>
            </q-bar>

            <q-card-section class="q-pa-sm">
                <div class="row q-gutter-sm q-mb-sm">
                    <q-input
                        label="类型名称" 
                       dense
                       outlined
                       class="col-9"
                        v-model="PdmBodycheckTaocantypeEntity.Type_Name "
                    />
                    <q-input
                        label="排序" 
                       dense
                       outlined
                       class="col-2"
                        v-model="PdmBodycheckTaocantypeEntity.Type_Sort "
                    />
                </div>
            </q-card-section>

            <q-card-actions align="right" class="bg-white text-teal">
                <q-btn flat label="取消" v-close-popup />
                <q-btn flat label="确定" @click="onOK" />
            </q-card-actions>
        </q-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
    components: {},
    props: {},
    computed: {
        ...mapState("PdmBodycheckTaocantype", [
            "PdmBodycheckTaocantypeShowDlg",
            "PdmBodycheckTaocantypeEntity",
            "PdmBodycheckTaocantypeList",
        ]),
     
     
     
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {
        ...mapActions("PdmBodycheckTaocantype", ["actUpdatePdmBodycheckTaocantype", "actGetPdmBodycheckTaocantypeList"]),
        onOK() {
         this.actUpdatePdmBodycheckTaocantype({
                        HospID: this.$store.getters["appUser/hospID"],
                        CUser: this.$store.getters["appUser/userID"],
                        Info: this.PdmBodycheckTaocantypeEntity,
                    }).then((res) => {
                        if (res.Code == 0) {
                           //setTimeout(()=> {
                             //this.$emit("onLoadData");
                           //}, 500);
                           this.$emit("onLoadData");
                           this.msg.success(res.Message);
                        }else{
                           this.msg.error(res.Message);
                        }
                      });
        },
    },
};
</script>
